enum StaticCharacteristicCode {
  IMEI = 'IMEI',
  ADDRESS = 'ADDRESS',
  ADMINSALASONA = 'ADMINSALASONA',
  ESIALGNE_PAROOL = 'ESIALGNE_PAROOL',
  GRUPITOODE_KASUTAJATUNNUS = 'GRUPITOODE_KASUTAJATUNNUS',
  GROUP_ID = 'GRUPI ID',
  PHONE_NUMBER = 'phoneNumber',
  SERVICE_TYPE_CODE = 'serviceTypeCode',
  SUBSCRIBER_FIRST_NAME = 'subscriberFirstName',
  SUBSCRIBER_LAST_NAME = 'subscriberLastName',
  SIM_NUMBER = 'simNumber',
  PUK_1_NUMBER = 'simPukNumber1',
  PUK_2_NUMBER = 'simPukNumber2',
  DEVICE_NUMBER = 'deviceNumber',
  KASUTAJATUNNUS = 'KASUTAJATUNNUS',
  NEW_SPEED = 'UUS_KIIRUS',
  SPEED = 'KIIRUS',
  HOME = 'home',
  DATA_LIMIT = 'DATA_LIMIT',
  DATA_FUP_LIMIT = 'DATA_FUP_LIMIT',
  TECHNOLOGY = 'TECHNOLOGY',
  LIMITED_LTE = 'LIMITED_LTE',
  INTERNET_NUMBER = 'INTERNET_NUMBER',
  UPLINK_SPEED = 'UPLINK_SPEED',
  DOWNLINK_SPEED = 'DOWNLINK_SPEED',
  OWNER = 'OWNER',
  NETWORK_OWNER = 'VORGU_OMANIK',
  SHOW_ON_INVOICE = 'showOnInvoice',
  COMMUNICATION_LANGUAGE = 'communicationLanguage',
  SPOTIFY_EMAIL = 'SPOTIFY_EMAIL',
  TV_SCREENS = 'TV_SCREENS',
  INTERNATIONAL_COVERAGE = 'internationalCoverage',
  STRICT = 'strict',
  SPEED_STRING = 'SPEED_STRING',
  CPE_NUMBER = 'CPE_NUMBER',
  TV_CHANNEL_ID = 'TV_CHANNEL_ID',
  TV_PACKAGE = 'TV_PACKAGE',
  FILTER = 'FILTER',
  CHARGETYPE = 'CHARGETYPE',
  PAKKUMISE_TEHNOLOOGIAD = 'PAKKUMISE_TEHNOLOOGIAD',
  INTERNET_PACKAGE_TYPE = 'internetPackageType',
  EXTRA_LISAMAHT = 'EXTRA LISAMAHT',
  GRUPI_EXTRA_LISAMAHT = 'GRUPI EXTRA LISAMAHT',
  MAHT = 'MAHT',
  LISAMAHT = 'LISAMAHT',
  MS_365_TENANT = 'MS_365_TENANT',
  MS_365_CUSTOMER_REFERENCE = 'MS_365_CUSTOMER_REFERENCE',
  MS_365_NUMBER = 'MS_365_NUMBER',
  MS_365_EMAIL = 'MS_365_EMAIL',
  MS_365_LICENSE_SIZE = 'MS_365_LICENSE_SIZE',
  IP_CIDR_PREFIX = 'IP_CIDR_PREFIX',
  PRIVATE_BILL = 'PRIVATE_BILL',
  RESTORE_ALLOWED = 'restoreAllowed',
  KASUTUSLIMIIT = 'KASUTUSLIMIIT',
  MOBILE_FORWARDING = 'MOBIILI_SUUNAMISED',
  SALASONA = 'SALASONA',
  SALVESTUSE_ADMIN = 'SALVESTUSE_ADMIN',
  SALVESTUSE_SALASONA = 'SALVESTUSE_SALASONA',
  SERT_KUNI = 'SERT KUNI',
  PORDID = 'PORDID',
  ERA_ARVE_MAKSED = 'ERA_ARVE_MAKSED',
  IP_AADRESS_ARV = 'IP_AADRESS_ARV',
}

export enum ConfigurableCharacteristicCode {
  LIMIT = 'LIMIT',
  MOBILE1 = 'MOBILE1',
  MOBILE2 = 'MOBILE2',
  NOTIFICATION_EMAIL_ADDRESS_1 = 'TEATE E-POSTI AADR1',
  NOTIFICATION_EMAIL_ADDRESS_2 = 'TEATE E-POSTI AADR2',
  TO_SELF = 'ISEENDALE',
  CHANGE = 'MUUTMINE',
  CONTACT_PERSON_EMAIL = 'CONTACT_PERSON_EMAIL',
  MANAGEABLE = 'JUHITAV',
  USAGE_LIMIT = 'KASUTUSLIMIIT',
  LANGUAGE = 'KEEL',
  CODE = 'KOOD',
  SMS_NOTICE = 'SMS TEAVITUS',
  NOTIFICATION_EMAIL_ADDRESS = 'TEATE E-POSTI AADR',
  MESSAGE_EMAIL_ADDRESS = 'SÕNUMI E-POSTI AADR',
  ANSWERING_MACHINE = 'AUTOMAATVASTAJA',
  FORWARDED_NUMBER = 'SUUNATUD NUMBRILE',
  ISIKUKOOD = 'ISIKUKOOD',
}

export type CharacteristicCode = StaticCharacteristicCode | ConfigurableCharacteristicCode;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CharacteristicCode = { ...StaticCharacteristicCode, ...ConfigurableCharacteristicCode };
