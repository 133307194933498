import { filterXSS } from 'xss';

type PlaceholderValue = string | number | undefined;

export interface PlaceholderMapping {
  [placeholder: string]: PlaceholderValue | PlaceholderMapping;
}

interface FlatPlaceholderMapping {
  [placeholder: string]: PlaceholderValue;
}

export class ParamsUtil {
  static parseParams(template: string, params: PlaceholderMapping, sanitizeParams = true): string {
    const flatParams = this.flattenParams(params);
    return Object.keys(flatParams).reduce((result, key) => {
      const value = flatParams[key] ? flatParams[key]!.toString() : '';
      return result.replace('${' + key + '}', sanitizeParams ? filterXSS(value) : value);
    }, template);
  }

  private static flattenParams(params: PlaceholderMapping): FlatPlaceholderMapping {
    const flattened: FlatPlaceholderMapping = {};

    for (const [key, value] of Object.entries(params)) {
      /* istanbul ignore else */
      if (this.isPlaceholderMapping(value)) {
        for (const [nestedKey, nestedValue] of Object.entries(this.flattenParams(value))) {
          flattened[key + '.' + nestedKey] = nestedValue;
        }
      } else if (this.isPlaceholderValue(value)) {
        flattened[key] = value;
      }
    }

    return flattened;
  }

  private static isPlaceholderMapping(value: PlaceholderValue | PlaceholderMapping): value is PlaceholderMapping {
    return Object.prototype.toString.call(value) === '[object Object]';
  }

  private static isPlaceholderValue(value: PlaceholderValue | PlaceholderMapping): value is PlaceholderValue {
    return ['number', 'string'].includes(typeof value);
  }
}
