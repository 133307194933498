import axios, {
  AxiosAdapter,
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestTransformer,
  AxiosResponse,
  Cancel,
} from 'axios';
import { ApiError } from '../model/api/ApiError';
import { ApiRequest } from '../model/api/ApiRequest';
import { ApiRequestConfig, HttpHeaders } from '../model/api/ApiRequestConfig';
import { ApiResponse, ApiResponseWithData, ApiResponseWithError } from '../model/api/ApiResponse';
import { HttpStatusCode } from '../model/api/HttpStatusCode';
import { ErrorUtil } from './ErrorUtil';
import { stringify } from 'qs';
import { cacheAdapterEnhancer } from 'axios-extensions';

export const API: AxiosInstance = axios.create({
  timeout: 60000,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
  paramsSerializer: /* istanbul ignore next */ (params) => stringify(params, { arrayFormat: 'repeat' }),
});

export const USER_CANCELLED_REQUEST = 'USER_CANCELLED_REQUEST';

// eslint-disable-next-line @typescript-eslint/ban-types
const isObject = (value: any): value is Object => Object.prototype.toString.call(value) === '[object Object]';

export const isApiError = (value: any): value is ApiError => Object.prototype.toString.call(value) === '[object Object]';

export const isApiResponseWithData = <T extends any = any>(value: any): value is ApiResponseWithData<T> =>
  isObject(value) && typeof value.data !== 'undefined';

export const isApiResponseWithError = (value: any): value is ApiResponseWithError =>
  isObject(value) && Array.isArray(value.errors) && isApiError(value.errors[0]);

export const isCancelledResponse = (response: ApiResponse) =>
  isApiResponseWithError(response) && response.errors[0].title === USER_CANCELLED_REQUEST;

/* istanbul ignore next */
export const isApiResponse = (value: any): value is ApiResponse => isApiResponseWithData(value) || isApiResponseWithError(value);

/* istanbul ignore next 39 */
export const makeRequest = <T>(requestConfig: ApiRequestConfig): ApiRequest<T> => {
  const cancelTokenSource = axios.CancelToken.source();
  const cancelRequest = () => cancelTokenSource.cancel(USER_CANCELLED_REQUEST);
  const headers: HttpHeaders = {
    Accept: 'application/json',
    ...requestConfig.headers,
  };
  const transformResponse: Array<AxiosRequestTransformer | AxiosRequestTransformer[]> = [validateJsonResponse];

  if (requestConfig.transformResponse) {
    transformResponse.push(requestConfig.transformResponse);
  }

  return {
    cancelRequest,
    responsePromise: API.request<ApiResponse<T>>({
      ...requestConfig,
      headers,
      cancelToken: cancelTokenSource.token,
      transformResponse: transformResponse.flat(),
    }).then(
      (response) => response.data,
      (error: AxiosError<ApiResponse>) => {
        if (error.message !== USER_CANCELLED_REQUEST && error.response?.status !== HttpStatusCode.SESSION_EXPIRED) {
          ErrorUtil.pushError(error);
        }
        return {
          errors: [
            {
              status: error.response?.status,
              title: error.message,
            },
            ...(error.response?.data?.errors || []),
          ],
        };
      }
    ),
  };
};

/* istanbul ignore next 25 */
export const validateJsonResponse = (responseBody: any, headers?: HttpHeaders) => {
  if (!isJsonContent(headers)) {
    return responseBody;
  }

  const reject = (message: string): ApiResponseWithError => ({
    errors: [
      {
        title: `API response is not valid JSON: ${message}`,
      },
    ],
  });

  if (typeof responseBody !== 'string') {
    return reject('Response body is not string');
  }

  try {
    responseBody = JSON.parse(responseBody);
  } catch (error: any) {
    return reject((error as SyntaxError).message);
  }

  return responseBody;
};

/* istanbul ignore next 12 */
const isJsonContent = (headers?: HttpHeaders) => {
  if (!headers) {
    return false;
  }

  const contentTypeValue = Object.entries(headers).reduce<string | undefined>(
    (contentType, [name, value]) => contentType || (name.toLowerCase() === 'content-type' ? value : undefined),
    undefined
  );

  return contentTypeValue?.includes('application/json');
};

// Reflecting https://github.com/axios/axios/blob/master/lib/cancel/isCancel.js
const isCancelledAxiosResponse = (value: any): value is Cancel => !!(value && value.__CANCEL__);
/**
 * Apply all given response interceptors except when intercepting the rejection of cancelled request
 */
export const interceptResponse: AxiosInterceptorManager<AxiosResponse>['use'] = (onFulfilled, onRejected) =>
  API.interceptors.response.use(onFulfilled, (error) => {
    if (onRejected && !isCancelledAxiosResponse(error)) {
      return onRejected(error);
    }
    return Promise.reject(error);
  });

type ResponseArray<T> = [Error | null, T?];

export const catchToArray = <T = any>(request: Promise<AxiosResponse<T>['data']>): Promise<ResponseArray<T>> => {
  if (!request || typeof request.then !== 'function') {
    throw new Error(
      `Expecting a Promise to be passed to catchToArray() utility. ${Object.prototype.toString.call(request)} passed instead`
    );
  }

  return request.then((data: AxiosResponse<T>['data']): ResponseArray<T> => [null, data]).catch((error: Error): [Error] => [error]);
};
