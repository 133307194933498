import * as dateFns from 'date-fns';

const isString = (input: Date | string | null): boolean => typeof input === 'string';

export const nowIsAfter = (input: string | null): boolean => {
  if (!input) {
    return false;
  }
  const date = dateFns.parseISO(input);
  if (!dateFns.isValid(date)) {
    return true;
  }
  return dateFns.isAfter(Date.now(), date);
};

export const isBeforeNow = (input: string): boolean => {
  if (!input) {
    return false;
  }
  const date = dateFns.parseISO(input);
  if (!dateFns.isValid(date)) {
    return true;
  }
  return dateFns.isBefore(date, Date.now());
};

export const nowIsBefore = (input: Date | undefined): boolean => {
  if (!input) {
    return false;
  }
  if (!dateFns.isValid(input)) {
    return true;
  }
  return dateFns.isBefore(Date.now(), input);
};

export const isBeforeOf = (date: Date | undefined): boolean => {
  if (!date) {
    return false;
  }
  return dateFns.isBefore(date, Date.now());
};

export const isAfterNow = (input: string): boolean => {
  if (!input) {
    return false;
  }
  const date = dateFns.parseISO(input);
  return dateFns.isAfter(date, Date.now());
};

export const firstIsAfterSecond = (first: Date | string | null, second: Date | string | null): boolean => {
  const date1 = isString(first) ? dateFns.parseISO(first as string) : (first as Date);
  const date2 = isString(second) ? dateFns.parseISO(second as string) : (second as Date);
  return dateFns.isAfter(date1, date2);
};

export const firstIsBeforeSecond = (first: Date | string | null, second: Date | string | null): boolean => {
  const date1 = isString(first) ? dateFns.parseISO(first as string) : (first as Date);
  const date2 = isString(second) ? dateFns.parseISO(second as string) : (second as Date);
  return dateFns.isBefore(date1, date2);
};

export const toUtcTimeZone = (date: Date): Date => dateFns.setMilliseconds(dateFns.addMinutes(date, date.getTimezoneOffset()), 0);

/* istanbul ignore next */
export const formatNow = (formatStr: string): string => formatDate(new Date(), formatStr);

export const formatDate = (input: string | Date | undefined, formatString: string): string => {
  if (input === undefined) {
    return formatDate(new Date(), formatString);
  }
  if (!input) {
    return 'Invalid date';
  }
  if (typeof input === 'string') {
    return formatDate(dateFns.parseISO(input), formatString);
  }
  if (!dateFns.isValid(input)) {
    return 'Invalid date';
  }
  if (formatString === 'DD.MM.YYYY') {
    return dateFns.format(input, 'dd.MM.yyyy');
  }
  if (formatString === 'DD.MM.YYYY HH:mm:ss') {
    return dateFns.format(input, 'dd.MM.yyyy HH:mm:ss');
  }
  if (formatString === 'DD.MM.Y') {
    return dateFns.format(input, 'dd.MM.y');
  }
  if (formatString === 'YYYYMMDD') {
    return dateFns.format(input, 'yyyyMMdd');
  }
  if (formatString === 'DD.MM.YYYY HH:mm') {
    return dateFns.format(input, 'dd.MM.yyyy HH:mm');
  }
  return dateFns.format(input, formatString);
};

export const toDate = (date: Date | string | undefined): Date => {
  /* istanbul ignore next 3 */
  if (!date) {
    return new Date();
  }

  if (isString(date)) {
    return dateFns.parseISO(date as string);
  }
  return date as Date;
};

export const formatICSTimestamp = (date: Date): string => `${formatDate(date, 'YYYYMMDD')}T${formatDate(date, 'HHmmss')}Z`;

export const formatBasicDate = (date: string | Date | null | undefined): string | undefined =>
  (date && formatDate(date, 'dd.MM.yyyy')) || undefined;

export const isValidDate = (input: string) => dateFns.isValid(dateFns.parseISO(input));
