import { Origin } from '../model/Origin';

export class GlobalProductIdUtil {
  private static readonly UNDERSCORE: string = '_';
  private static readonly BEGINNING_INDEX: number = 0;
  private static readonly NEXT_SYMBOL_INDEX: number = 1;

  static getOrigin(globalProductId: string): Origin {
    const originKey = globalProductId.split(GlobalProductIdUtil.UNDERSCORE)[GlobalProductIdUtil.NEXT_SYMBOL_INDEX] as keyof typeof Origin;
    return Origin[originKey];
  }

  static getProductId(globalProductId: string): number {
    return +globalProductId.substring(GlobalProductIdUtil.BEGINNING_INDEX, globalProductId.indexOf(GlobalProductIdUtil.UNDERSCORE));
  }

  static getGlobalProductId(productId: number, origin: Origin): string {
    return `${productId}${GlobalProductIdUtil.UNDERSCORE}${origin}`;
  }
}
